import axios from 'axios';
import Vue from 'vue';

const backendURL = process.env.VUE_APP_BASE_URL;
Vue.prototype.setHeader = (key, value) => {
    axios.defaults.headers.common[key] = value;
};

Vue.prototype.get = (url, params = {}) => axios.get(backendURL + url, { params });
Vue.prototype.post = (url, data) => axios.post(backendURL + url, data);
Vue.prototype.patch = (url, data) => axios.patch(backendURL + url, data);
Vue.prototype.put = (url, data) => axios.put(backendURL + url, data);
Vue.prototype.delete = (url, data = {}) => axios.delete(backendURL + url, data);
