import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Main from '../views/Main.vue';

Vue.use(VueRouter);

const routes = [
    {
        component: Login,
        name: 'Login',
        path: '/login',
    },
    {
        component: Main,
        path: '',
        name: 'Main',
        children: [],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
