<template>
    <div class="registration-input">
        <message :text="currentText" @printed="showInput = true">
            <template v-if="showInput">
                <div v-if="currentChoice.id === 1" class="meme-grid">
                    <template v-for="(meme, index) in memes">
                        <meme-dialog
                            :meme="meme"
                            :key="index"
                            @click="makeChoice((index + 1).toString())"
                        ></meme-dialog>
                    </template>
                </div>

                <div class="choice-grid" v-else-if="currentChoice.values">
                    <c-button
                        @click="makeChoice(value)"
                        size="small"
                        v-for="value in currentChoice.values"
                        :key="value"
                    >
                        {{ value }}
                    </c-button>
                </div>

                <div v-else class="d-flex align-end flex-column">
                    <c-input v-model="selectedValue"></c-input>
                    <c-button
                        @click="makeChoice(selectedValue)"
                        :disabled="selectedValue.length <= 0"
                        size="small"
                        class="mt-2"
                    >
                        Отправить
                    </c-button>
                </div>
            </template>
        </message>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MemeDialog from '../../components/MemeDialog.vue';

export default {
    props: {},

    components: {
        MemeDialog,
    },

    data() {
        return {
            showInput: false,
            selectedValue: '',
        };
    },

    mounted() {},

    methods: {
        ...mapActions('users', ['sendChoice']),

        makeChoice(val) {
            this.sendChoice(val).then(() => this.$emit('input', val));
        },
    },

    computed: {
        ...mapState('users', ['currentUser']),
        ...mapGetters('users', ['choiceById']),

        currentChoice() {
            return this.choiceById(this.currentUser.choice_id);
        },

        memes() {
            const arr = [];
            for (let x = 1; x <= 8; x += 1) {
                // eslint-disable-next-line global-require,import/no-dynamic-require
                arr.push(require(`../../assets/memes/${x}.jpg`));
            }
            return arr;
        },

        currentText() {
            return (
                'В общем, что я там говорил? Фичи, да.b          ' +
                'Начнем с первой.bb          ' +
                `Методом рандома тебе достается на выбор "${this.currentChoice.parentalName}".bb                   ` +
                `${this.currentChoice.text}` +
                'bА по итогу, твой Санта постарается придумать что-то, что будет с этим связано.b' +
                'Только думай тщательно и не глупи, Санта тоже человек.'
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.meme-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
}

.choice-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
}
</style>
