<template>
    <div class="registration-page">
        <template v-if="!currentUser.created_mail || currentStep > 1">
            <template v-if="currentStep === 1">
                <notification @click="nextStep(2)"></notification>
            </template>

            <template v-else-if="currentStep === 2">
                <message :text="steps[1].text" @printed="showButton = true"></message>
                <c-button @click="nextStep(3)" class="mt-10" v-if="showButton">
                    И что за сюрприз?...
                </c-button>
            </template>

            <template v-else-if="currentStep === 3">
                <message :text="steps[2].text" @printed="showButton = true"></message>
                <c-button @click="nextStep(4)" class="mt-10" v-if="showButton">
                    О да, наконец-то!!!
                </c-button>
            </template>

            <template v-else-if="currentStep === 4">
                <message :text="steps[3].text" @printed="showButton = true"></message>
                <c-button @click="nextStep(5)" class="mt-10" v-if="showButton">Давай!!</c-button>
            </template>

            <template v-else-if="currentStep === 5">
                <input-component @input="nextStep(6)"></input-component>
            </template>

            <template v-else-if="currentStep === 6">
                <message :text="steps[5].text" @printed="showButton = true"></message>
                <c-button @click="nextStep(1)" class="mt-10" v-if="showButton">
                    Работаем!
                </c-button>
            </template>
        </template>
        <template v-if="currentStep === 1 && currentUser.created_mail">
            <loading width="200px"></loading>
            <span class="font-weight-bold highlited headline">
                Санкт-Петербург, метро Кристово, работаем
            </span>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import InputComponent from './InputComponent.vue';

export default {
    props: {},

    components: {
        InputComponent,
    },

    data() {
        return {
            currentStep: 1,
            showButton: false,
        };
    },
    methods: {
        nextStep(step) {
            this.currentStep = undefined;
            this.showButton = false;
            setTimeout(() => {
                this.currentStep = step;
            }, 100);
        },
    },

    computed: {
        ...mapState('users', ['currentUser']),
        steps() {
            return [
                {},
                {
                    text:
                        'Приветик!b' +
                        'Вот и заканчивается еще один год...b' +
                        'Действительно, он был непростым, мы многое пережили вместе, а дальше будет еще сложнееb' +
                        'Что ж, не грусти, у меня есть для тебя один сюрприз!',
                },
                {
                    text:
                        `Ну как это что за сюрприз, ${this.currentUser.first_name}!b` +
                        'Разве уже не стало понятно, что это ежегодный тайный санта в Crysto!b' +
                        'И, как и в прошлый раз, будет он не совсем обычным!',
                },
                {
                    text:
                        'Как вы помните, в нашего Санту мы решили добавить пару небольших фич.b       ' +
                        'И ты сам, и тебе будут предложены определенные условия, которые нужно будет' +
                        ' выполнить тебе и твоему Тайному Санте.b       ' +
                        'Готов начать?',
                },
                {},
                {
                    text:
                        'Отлично, выбор сделан, я передам твои пожелания твоему Санте!b              ' +
                        'Спрашиваешь, что по поводу второй фичи?b         ' +
                        'О ней ты узнаешь позже, когда я получу письма ото всех детишекb         ' +
                        'А пока что можешь идти работать, не забывай проверять таски в Ютреке!',
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.registration-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
