<template>
    <div
        class="button no-select"
        :class="`${size} ${disabled ? 'disabled' : 'hoverable clickable'}`"
        @click="emitClick"
    >
        <slot name="default"></slot>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            format: String,
            default: 'medium',
        },
        disabled: {
            format: Boolean,
            default: false,
        },
    },

    components: {},

    data() {
        return {};
    },

    mounted() {},

    methods: {
        emitClick(e) {
            if (!this.disabled) this.$emit('click', e);
        },
    },

    computed: {},
};
</script>

<style lang="scss" scoped>
.button {
    padding: 10px;
    display: inline-flex;
    justify-content: center;
    min-width: 200px;
    font-size: 18px;
    border: 2px solid $christmas-red;
    color: $white-color;
    background-color: $christmas-red;
    font-weight: 500;
    border-radius: 5px;

    &.disabled {
        background-color: grey;
        border: 2px solid grey;
    }

    &.small {
        font-size: 14px;
        padding: 5px;
    }
}
</style>
