<template>
    <input type="text" v-model="inputVal" placeholder="Вводить сюда" />
</template>

<script>
export default {
    props: {
        value: {
            format: String,
        },
    },

    components: {},

    data() {
        return {};
    },

    mounted() {},

    methods: {},

    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
input {
    font-size: 18px;
    outline: none;
    border-radius: 5px;
    border: 1px solid $christmas-red;
    padding: 5px;
    width: 100%;
    color: $christmas-red;
}
</style>
