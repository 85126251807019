<template>
    <div class="login-page">
        <h1>Тайный санта Crysto</h1>
        <a :href="authLink">
            <c-button class="auth-button">Авторизация через вк</c-button>
        </a>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {},

    components: {},

    data() {
        return {};
    },

    mounted() {
        if (this.$route.query.code) {
            this.authorizeUser(this.$route.query.code).then(() => {
                this.$router.push({ name: 'Main' });
            });
        }
    },

    methods: {
        ...mapActions('users', ['authorizeUser']),
    },

    computed: {
        authLink() {
            return (
                'https://oauth.vk.com/authorize?' +
                `client_id=${process.env.VUE_APP_VK_CLIENT_ID}&` +
                'display=page&' +
                `redirect_uri=${process.env.VUE_APP_REDIRECT_URL}&` +
                'scope=friends&' +
                'response_type=code&' +
                'v=5.131'
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.login-page {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        color: $white-color;
        position: absolute;
        top: 20vh;
        font-size: 96px;
    }
}
</style>
