<template>
    <div class="target" v-if="loaded">
        <div>
            <img class="avatar" :src="targetUser.avatar_url" alt="" />
        </div>
        <div class="description">
            <div class="highlited">{{ targetUser.first_name }} {{ targetUser.last_name }}</div>
            <div>
                Пожелание цели: <span class="highlited">{{ currentChoice.name }}</span>
            </div>
            <div>
                Описание: <span class="highlited">{{ currentChoice.description }}</span>
            </div>
            <div>
                Комментарий цели:
                <span v-if="currentChoice.id !== 1" class="highlited">
                    {{ targetUser.commentary }}
                </span>
                <meme-dialog :actioned="false" :meme="memeURL"></meme-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MemeDialog from '../../components/MemeDialog.vue';

export default {
    props: {},

    components: {
        MemeDialog,
    },

    data() {
        return {
            loaded: false,
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        ...mapActions('users', ['fetchTarget']),

        async load() {
            await this.fetchTarget();
            this.loaded = true;
        },
    },

    computed: {
        ...mapState('users', ['targetUser', 'usersChoices']),

        memeURL() {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            if (this.currentChoice.id === 1)
                // eslint-disable-next-line global-require,import/no-dynamic-require
                return require(`../../assets/memes/${this.targetUser.commentary}.jpg`);
            return '';
        },

        currentChoice() {
            return this.usersChoices[this.targetUser.choice_id];
        },
    },
};
</script>

<style lang="scss" scoped>
.target {
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1em;

    .description {
    }

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
    }
}
</style>
