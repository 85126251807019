<template>
    <div class="main-page">
        <registration v-if="currentStage === 'registration'"></registration>
        <tasks v-else-if="currentStage === 'tasks'"></tasks>
    </div>
</template>

<script>
import Tasks from './Task/Tasks.vue';
import Registration from './Registration/Registration.vue';

export default {
    props: {},

    components: {
        Tasks,
        Registration,
    },

    data() {
        return {
            currentStage: 'tasks',
        };
    },

    mounted() {},

    methods: {},

    computed: {},
};
</script>

<style lang="scss" scoped>
.main-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
