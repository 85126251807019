<template>
    <div class="notification hoverable clickable" @click="emitClick">
        <v-icon>mdi-bell</v-icon>
        <div class="text">
            <slot>У вас 1 непрочитанное уведомление</slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {},

    components: {},

    data() {
        return {};
    },

    mounted() {},

    methods: {
        emitClick() {
            this.$emit('click');
        },
    },

    computed: {},
};
</script>

<style lang="scss" scoped>
.notification {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 300px;
    background: $white-color;
    border-radius: 10px;

    i {
        padding: 30px;
        color: $christmas-red;
    }

    .text {
        font-size: 18px;
    }
}
</style>
