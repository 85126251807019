<template>
    <div class="tasks-page">
        <template v-if="currentStep === -1">
            <notification @click="nextStep(0)"></notification>
        </template>

        <template v-for="(step, index) in steps">
            <template v-if="currentStep === index">
                <div :key="index" class="d-flex flex-column align-center">
                    <message :text="step.text" @printed="showButton = true">
                        <target v-if="step.targetComponent && showButton"></target>
                    </message>
                    <c-button
                        @click="nextStep(index + 1)"
                        class="mt-10"
                        v-if="showButton && step.buttonText"
                    >
                        {{ step.buttonText }}
                    </c-button>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Target from './Target.vue';

export default {
    props: {},

    components: {
        Target,
    },

    data() {
        return {
            currentStep: -1,
            showButton: false,
        };
    },

    mounted() {},

    methods: {
        ...mapActions('users', ['targetViewed']),

        stepAction(step) {
            if (step === 3) this.targetViewed();
        },

        nextStep(step) {
            this.showButton = false;
            this.currentStep = undefined;
            setTimeout(() => {
                this.currentStep = step;
                this.stepAction(step);
            }, 100);
        },
    },

    computed: {
        ...mapState('users', ['currentUser', 'modifications']),

        currentModification() {
            return this.modifications[this.currentUser.mod_id];
        },

        steps() {
            if (!this.currentModification) return [];
            return [
                {
                    text:
                        'Ну что, настало время для второго этапа, да?b            ' +
                        'Итак, что тебя ждет на втором этапе...b            ' +
                        'Сначала ты узнаешь условие, которое тебе необходимо будет выполнить при оформлении подаркаb            ' +
                        'Потом ты узнаешь свою цель с её пожеланием.b            ' +
                        'А дальше я немного расскажу про правила вручения подарка.bb            ' +
                        'Понял?',
                    buttonText: 'Понял!',
                },
                {
                    text:
                        'Тебе достался следующий модификатор.bb            ' +
                        `${this.currentModification.text}bb` +
                        'Учти, что этот модификатор не должен влиять на сам подарок, он влияет только на упаковку или её содержимое.b            ' +
                        'А теперь перейдем к твоей цели',
                    buttonText: 'Давай сюда быстрее мою цель',
                },
                {
                    text: 'Вот твоя цель:b',
                    targetComponent: true,
                    buttonText: 'Цель принял, что по правилам?',
                },
                {
                    text:
                        'А вот мы дошли и до правил:b' +
                        'Правило первое – никто не должен знать о том, чей ты Санта вплоть до вручения подарков;b            ' +
                        'Правило второе – никто не должен знать ничего о модификаторе твой цели;b            ' +
                        'Правило третье – подарки дарим 16 декабря в Токио Сити;b            ' +
                        'Правило четвертое – подарок должен быть упакован с учетом модификатора, который достался тебе ранее;b            ' +
                        'Правило пятое – на подарке должно быть закреплено имя того, кому вручают подарок;b            ' +
                        'Правило шестое – на сам стараемся уложиться в пределах тысячи рублей, на модификатор в пределах двухсот.bb' +
                        'Все принесенные подарки будут помещаться в большой мешок со всеми подарками и в момент вручения мы будем по очереди доставить их из него и по имени дарить.b            ' +
                        'Такая схема гарантирует то, что даже организаторы не узнают, кто чей Санта.bb            ' +
                        'Правила понятны?',
                    buttonText: 'Таки да!',
                },
                {
                    text:
                        'Отлично!b' +
                        'Тогда всем желаю хорошего рабочего дня, с любовью Crysto-Санта!bb' +
                        'Если вы забыли ваше условие упаковки и цель – перезайдите на сайт',
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.tasks-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
