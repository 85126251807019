import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import Vue from 'vue';
import CrystoLogo from '../components/Icons/CrystoLogo.vue';
import CButton from '../components/CButton.vue';
import CInput from '../components/CInput.vue';
import Loading from '../components/Icons/Loading.vue';
import Message from '../components/Message.vue';
import Notification from '../components/Notification.vue';

Vue.prototype.formattedDate = (arg, str) => (arg ? format(arg, str, { locale: ru }) : '');
Vue.component('CrystoLogo', CrystoLogo);
Vue.component('CInput', CInput);
Vue.component('CButton', CButton);
Vue.component('Message', Message);
Vue.component('Loading', Loading);
Vue.component('Notification', Notification);
