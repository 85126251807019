<template>
    <img :width="width" src="../../assets/pasha.gif" alt="" />
</template>

<script>
export default {
    props: {
        width: {
            format: String,
            default: '100px',
        },
    },

    components: {},

    data() {
        return {};
    },

    mounted() {},

    methods: {},

    computed: {},
};
</script>

<style lang="scss" scoped></style>
