<template>
    <v-app>
        <v-main class="main-app">
            <crysto-logo></crysto-logo>
            <div class="main-app-wrapper">
                <template v-if="loaded">
                    <router-view> </router-view>
                </template>
                <div v-else>
                    <h1>Загружаем...</h1>
                </div>
            </div>
            <div class="mobile-block">
                <h1>К сожалению, сайт работает только на десктопах(</h1>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {},

    components: {},

    data() {
        return {
            loaded: false,
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        ...mapActions('users', ['initializeUser']),

        async load() {
            if (!this.token) {
                if (this.$route.name !== 'Login') {
                    await this.$router.push({ name: 'Login' });
                }
                this.loaded = true;
            } else {
                this.initializeUser().then(() => {
                    this.loaded = true;
                });
            }
        },
    },

    computed: {
        ...mapGetters('users', ['token']),
    },
};
</script>

<style lang="scss" scoped>
.main-app {
    .crysto-logo {
        position: absolute;
        margin: 25px;
    }

    .main-app-wrapper {
        height: 100vh;
    }
}

.mobile-block {
    display: none;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    justify-content: center;
}

@media all and (max-width: 1023px) {
    .main-app-wrapper {
        display: none;
    }

    .mobile-block {
        display: flex;
    }
}
</style>
