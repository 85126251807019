import { render, staticRenderFns } from "./MemeDialog.vue?vue&type=template&id=f74677a8&scoped=true"
import script from "./MemeDialog.vue?vue&type=script&lang=js"
export * from "./MemeDialog.vue?vue&type=script&lang=js"
import style0 from "./MemeDialog.vue?vue&type=style&index=0&id=f74677a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f74677a8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VCardActions,VCardText,VDialog})
