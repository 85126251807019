<template>
    <div class="message">
        <img class="avatar" src="../assets/santa.png" alt="" />
        <div class="name-from">Владислав Хоу-хоу-чук</div>
        <div class="text" v-html="currentText"></div>
        <div class="actions">
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            format: String,
        },
    },

    components: {},

    data() {
        return {
            currentText: '',
            printerInterval: undefined,
        };
    },

    mounted() {
        setTimeout(() => this.printText(this.text), 500);
    },

    methods: {
        printText(text) {
            let index = 0;
            this.printerInterval = setInterval(() => {
                const char = text.charAt(index);
                if (!char) {
                    clearInterval(this.printerInterval);
                    setTimeout(() => this.$emit('printed'), 1000);
                }
                this.currentText += char === 'b' ? '<br>' : char;
                index += 1;
            }, 30);
        },
    },

    computed: {},
};
</script>

<style lang="scss" scoped>
.message {
    display: grid;
    grid-template-areas:
        'a b'
        'a c'
        'a d';
    grid-template-columns: 100px auto;
    background-color: $white-color;
    border-radius: 10px;
    padding: 10px;
    grid-column-gap: 10px;
    max-width: 800px;

    .avatar {
        grid-area: a;
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    .name-from {
        grid-area: b;
        color: $christmas-red;
        height: min-content;
    }

    .text {
        grid-area: c;
    }

    .actions {
        grid-area: d;
    }
}
</style>
