<template>
    <div class="meme-dialog">
        <img class="hoverable" @click="dialog = true" :src="meme" alt="" />
        <v-dialog max-width="1000px" v-model="dialog">
            <v-card>
                <v-card-text class="d-flex justify-center">
                    <img style="max-width: unset; max-height: 80vh" :src="meme" alt="" />
                </v-card-text>
                <v-card-actions v-if="actioned" class="d-flex justify-center">
                    <c-button @click="select">Хочу его</c-button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        meme: {
            format: String,
        },
        actioned: {
            format: Boolean,
            default: true,
        },
    },

    components: {},

    data() {
        return {
            dialog: false,
        };
    },

    mounted() {},

    methods: {
        select() {
            this.dialog = false;
            this.$emit('click');
        },
    },

    computed: {},
};
</script>

<style lang="scss" scoped>
img {
    align-self: center;
    justify-self: center;
    max-width: 200px;
    max-height: 200px;
    cursor: pointer;
}

@media screen and (max-height: 900px) {
    img {
        align-self: center;
        justify-self: center;
        max-width: 125px;
        max-height: 125px;
        cursor: pointer;
    }
}
</style>
